<!-- 人员考勤详情 -->
<template>
  <div class="userAttendanceDetails">
    <el-row>
      <el-col :span="11">
        <div class="table_wrapper">
          <infoTable :tableData="pdaManagerInfo" :num="2"></infoTable>
        </div>
        <div
          class="map"
          :class="isScreen ? 'screen-box' : ''"
          @click.self="handleScreen"
        >
          <el-amap
            vid="amap"
            :amap-manager="amapManager"
            :center="center"
            :zoom="zoom"
            :showIndoorMap="false"
            class="amap-demo"
          >
            <!-- <el-amap-polyline
              :editable="polyline.editable"
              :path="polyline.path"
              :events="polyline.events"
            ></el-amap-polyline> -->

            <el-amap-marker
              ref="circle"
              vid="circle-marker"
              v-for="(item, index) in circleList"
              :key="index"
              :position="item.position"
              :content="item.content"
              :offset="[-5, -10]"
              :zIndex="101"
            ></el-amap-marker>
            <el-amap-marker
              v-for="(marker, index) in markers"
              :content="marker.content"
              :position="marker.position"
              :key="marker.content"
              :events="marker.events"
              :offset="[-19, -55]"
              :visible="marker.visible"
              :draggable="marker.draggable"
              :vid="index"
            ></el-amap-marker>
            <el-amap-marker
              v-if="isPlayback && polyline.path.length"
              ref="trajectoryMarker"
              vid="trajectory-marker"
              :position="trajectory.position"
              :content="trajectory.content"
              :offset="[-16, -32]"
              :zIndex="101"
            ></el-amap-marker>
            <el-amap-info-window
              :position="windowsData[0].position"
              :content="windowsData[0].content"
              :visible="windowsData[0].visible"
              :events="windowsData[0].events"
              :zIndex="10"
              :offset="[0, -48]"
            ></el-amap-info-window>
            <div v-if="positionSwitch">
              <el-amap-text
                v-for="(text, index) in texts"
                :key="index"
                :text="text.text"
                :offset="text.offset"
                :position="text.position"
              ></el-amap-text>
            </div>
            <div class="operation-box">
              <!-- <span class="position-text playback-span">轨迹回放</span> -->
              <!-- <el-switch v-model="isPlayback"> </el-switch> -->

              <span class="position-text">时间回放</span>
              <el-switch v-model="dynamicRolling"> </el-switch>

              <span class="line-box"></span>
              <span class="position-text">定位时间</span>
              <el-switch v-model="positionSwitch"> </el-switch>
              <span class="line-box"></span>
              <img
                class="enlarge-img"
                src="../../../assets/img/enlarge-icon.png"
                alt=""
                @click="handleScreen"
              />
            </div>
          </el-amap>
        </div>
        <el-button
          style="float: right; margin-top: 20px"
          @click="$router.go(-1)"
          type="primary"
          >返回</el-button
        >
      </el-col>
      <el-col :span="13">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :cell-style="cellStyle"
        >
          <el-table-column
            align="center"
            v-for="item in tableCols"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :formatter="item.formatter"
          >
          </el-table-column>
          <el-table-column
            :label="$t('list.operation')"
            align="center"
            width="90"
          >
            <!-- v-if="$route.meta.authority.button.view" -->
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="edit(scope.row)"
                >查看轨迹</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import { dateFormat } from "@/common/js/public";
import { AMapManager } from "vue-amap";
import infoTable from "@/components/infoTable/infoTable";
let amapManager = new AMapManager();
export default {
  components: { infoTable },
  data() {
    return {
      imageOut: require("./img/out.png"),
      imageInside: require("./img/inside.png"),
      // 圆点图标
      circleList: [],
      amapManager,
      polyline: {
        path: [],
        editable: false,
      },
      isPlayback: false, // 是否回放轨迹
      zoom: 10,
      center: [116.398004, 39.914218],
      markers: [],
      pointArr: [],
      windowsData: [
        {
          position: [121.5273285, 31.25515044],
          content: "<span>window</span>",
          events: {},
          visible: false,
        },
      ],
      texts: [
        // {
        //   position: [116.313077, 39.982089],
        //   text: "hello world",
        //   offset: [0, 0],
        //   events: {
        //     click: () => {
        //       alert("click text");
        //     },
        //   },
        // },
      ],
      tableData: [],
      tableCols: [
        {
          prop: "dataDate",
          label: this.$t("list.date"),
          width: "",
        },
        {
          prop: "clockInTime",
          label: this.$t("list.clock_in_time_for_work"),
          width: "",
        },
        {
          prop: "clockOutTime",
          label: this.$t("list.Check_in_time_after_work"),
          width: "",
        },
        {
          prop: "onDutyTime",
          label: this.$t("list.Working_hours"),
          width: "",
        },
      ],
      pdaManagerInfo: [],
      routeData: {},
      page: 1,
      pageSize: 15,
      total: 0,
      pdaManagerId: "",
      positionSwitch: false,
      dynamicRolling: false,
      isScreen: false,
      trajectory: {
        position: [116.313093, 39.982087],
        content: '<span class="trajectory-icon"></span>',
      },
      timeinfoShow: null,
      infowindow1: null,
      circle: null,
    };
  },
  // 方法集合
  methods: {
    getinitMap(lnglats, timeShow) {
      let o1 = amapManager.getMap();
      // var lnglats = [[116.39, 39.92], [116.41, 39.93], [116.43, 39.91], [116.46, 39.93]];
      var indexNum = 0;
      this.timeinfoShow = setInterval(() => {
        this.infowindow1 ? this.infowindow1.close() : "";
        // 卡片信息
        // let content = '我是第' + (lnglats[indexNum].reportHour) + '个Marker';

        let content = `<div class="infoMarker" style="text-align: center;color: #ffffff;border-raidus:10px;padding: 5px;">
            <p style="background: rgba(0, 0, 0, 0.8);">${
              lnglats[indexNum].reportHour
            }</p>
            <img src="${
              lnglats[indexNum].outOfRange == 0
                ? this.imageInside
                : this.imageOut
            }">
          </div>`;

        // 显示卡片
        this.infowindow1 = new AMap.InfoWindow({
          isCustom: true,
          content: content,
          // offset: new AMap.Pixel(15, 10),
          offset: new AMap.Pixel(0, 10),
        });
        // 保持回放时间地图中心点显示
        o1.setCenter([
          lnglats[indexNum].longitude / 1000000,
          lnglats[indexNum].latitude / 1000000,
        ]);
        // 打开信息窗体
        this.infowindow1.open(o1, [
          lnglats[indexNum].longitude / 1000000,
          lnglats[indexNum].latitude / 1000000,
        ]);
        indexNum++;
        if (indexNum > lnglats.length - 1) {
          indexNum = 0;
        }
      }, 2000);
    },
    handleScreen() {
      this.isPlayback = false;
      this.isScreen = !this.isScreen;
    },
    handleMotion() {
      if (this.polyline.path.length) {
        // console.log(this.polyline.path)
        setTimeout(() => {
          // let o1 = amapManager.getMap();
          this.$refs.trajectoryMarker
            .$$getInstance()
            .moveAlong(this.polyline.path, 800);
          // this.$refs.trajectoryMarker.$$getInstance().on('moving', function (e) {
          //   o1.setCenter(e.target.getPosition(), true);
          // })
        });
      }
    },
    // 关闭全屏
    handleCloseCreen() {
      if (this.isPlayback && this.polyline.path.length) {
        this.handleMotion();
      }
    },
    cellStyle(row, column, rowIndex, columnIndex) {
      if (
        row.column.label === "上班打卡时间" &&
        (row.row.clockInTime === "未打卡" || row.row.exceptTypeOn == 1)
      ) {
        return "color:red";
      } else if (
        row.column.label === "下班打卡时间" &&
        (row.row.clockOutTime === "未打卡" || row.row.exceptTypeOff == 1)
      ) {
        return "color:red";
      }
    },
    handleCurrentChange(v) {
      this.page = v;
      this.getDetails();
    },
    getDetails() {
      // /pdaManager/attend/attendance/detail
      this.$axios
        .get("/acb/2.0/pdaManager/attend/attendance/detail", {
          data: {
            pdaManagerCode: this.routeData.pdaManagerCode,
            dateType: this.routeData.dateType,
            // pdaManagerCode: "1662232815874371587",
            // dateType: "1",
            startDate: this.routeData.startTime,
            // startDate: "2020-5-21 00:00:00",
            endDate: this.routeData.endTime,
            // endDate: "2020-5-22 00:00:00",
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          // this.tableData = res.value.list;
          let DataList = res.value.list;
          if (DataList.length) {
            let sameDay = DataList[0];
            const nowDate = new Date().toLocaleDateString().replace(/\//g, "-");
            if (
              sameDay.clockInTime &&
              sameDay.clockInTime != "未打卡" &&
              !sameDay.clockOutTime &&
              nowDate == sameDay.dataDate
            ) {
              const onDutyTime = this.timeFn(
                `${sameDay.dataDate} ${sameDay.clockInTime}`
              );
              sameDay.onDutyTime = onDutyTime;
            }
            this.tableData = DataList;
            this.total = res.value.total * 1;
            this.edit(sameDay);
          }
        });
    },
    timeFn(dateBegin) {
      // 如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      var dateEnd = new Date(); // 获取当前时间
      var dateDiff = dateEnd.getTime() - new Date(dateBegin).getTime(); // 时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); // 计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); // 计算出小时数
      // 计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); // 计算相差分钟数
      // 计算相差秒数
      var leave3 = leave2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);

      var leave4 = leave3 % (60 * 1000); // 计算分钟数后剩余的毫秒数
      var minseconds = Math.round(leave4 / 1000);
      var timeFn =
        (dayDiff ? dayDiff + "天" : "") +
        (hours ? hours + "小时" : "") +
        (minutes ? minutes + "分" : "") +
        (seconds ? seconds + "秒" : "");
      // minseconds +
      // "毫秒";
      return timeFn;
    },
    getPdaInfo() {
      this.$axios
        .get("/acb/2.0/pdaManager/getByCode/" + this.routeData.pdaManagerCode, {
          data: {
            deptName: 1,
          },
        })
        .then((res) => {
          let role = res.value.pdaManagerRole;
          this.pdaManagerId = res.value.pdaManagerId;
          this.pdaManagerInfo = [
            {
              value: res.value.pdaManagerName,
              name: "姓名",
            },
            {
              value: res.value.account,
              name: "账号",
            },
            {
              value: role,
              name: "角色",
              format: (role) => {
                // dataObj 是当前对象
                // return role == 1 ? "收费员" : role == 2 ? "<span style='color:#ff0000'>稽查员</span>" : "";
                // console.log(role)
                let roleObj = {
                  1: "收费员",
                  2: "稽查员",
                  3: "网点",
                  4: "中队长",
                  5: "管理员",
                };
                return roleObj[role];
                // return role == 1 ? "收费员" : role == 2 ? "稽查员" : "";
              },
            },
            {
              value: res.value.operationName,
              name: "所属运营商",
            },
            {
              value: res.value.mobile,
              name: "手机号",
            },
            {
              value: "",
              name: "",
            },
          ];
          this.getDetails();
        });
    },
    setMap(obj, startPoint, endPoint) {
      this.markers = [];
      if (startPoint.length > 0) {
        this.markers.push({
          position: startPoint,
          events: {
            click: () => {
              this.windowsData[0].visible = true;
              this.windowsData[0].position = startPoint;
              this.windowsData[0].content = `<div>
                    <div>时间：${obj.signinTime}</div>
                    <div>地点：${obj.signinAddress}</div>
                </div>
                `;
            },
          },
          visible: true,
          draggable: false,
          content: '<span class="up"></span>',
        });
      }
      if (endPoint.length > 0) {
        this.markers.push({
          position: endPoint,
          events: {
            click: () => {
              this.windowsData[0].visible = true;
              this.windowsData[0].position = endPoint;
              this.windowsData[0].content = `<div>
                    <div>时间：${obj.signoutTime}</div>
                    <div>地点：${obj.signoutAddress}</div>
                </div>
                `;
            },
          },
          visible: true,
          draggable: false,
          content: '<span class="down"></span>',
        });
      }
    },

    handleData(pointData, dataList) {
      let o1 = amapManager.getMap();

      if (this.circle) {
        o1.clearMap();
      }

      let arr1 = pointData[1].elecFenceSettings || [];
      let arr2 = dataList.elecFenceSettings || [];
      let circleConcat = arr1.concat(arr2);

      let circleConcatList;
      if (circleConcat.length > 0) {
        circleConcatList = circleConcat.filter((value, index, array) => {
          // 根据⾃⼰的条件进⾏过滤
          return (
            array.findIndex((item) => item.parkName === value.parkName) ===
            index
          );
        });
      }

      this.circleList = [];
      setTimeout(() => {
        let pointArr = [],
          timeArr = [];
        circleConcatList.forEach((item) => {
          this.circle = new AMap.Circle({
            center: [item.longitude / 1000000, item.latitude / 1000000],
            radius: item.attendDistance || 0, // 半径
            // borderWeight: 3,
            strokeColor: "#0089ff",
            strokeOpacity: 1,
            // strokeWeight: 6,
            fillOpacity: 0.3,
            // strokeStyle: 'dashed',
            // strokeDasharray: [10, 10],
            // 线样式还支持 'dashed'
            fillColor: "#0089ff",
            zIndex: 50,
          });
          this.circle.setMap(o1);
        });
        pointData.forEach((v) => {
          let lng = v.longitude / 1000000;
          let lat = v.latitude / 1000000;
          pointArr.push([lng, lat]);
          // 设置时间文本
          if (v.reportHour) {
            const params = {
              position: [lng, lat],
              text: v.reportHour,
              offset: [0, 12],
            };
            timeArr.push(params);
          }

          let lnglat = new AMap.LngLat(
            v.longitude / 1000000,
            v.latitude / 1000000
          );
          let num = 0;
          // let isContain  = circle.contains(lnglat)
          // if (isContain) {
          //   console.log("在圆内")
          // } else {
          //   console.log("不在圆内")
          // }
          if (v.outOfRange == 0 || v.outOfRange == 1) {
            this.circleList.push({
              position: [v.longitude / 1000000, v.latitude / 1000000],
              content: `<div class="circle" id="${
                v.pdaMonitorId
              }" style="width: 10px;height: 10px;border-radius: 50%;background: ${
                v.outOfRange == 0
                  ? "rgba(0, 222, 143, 1)"
                  : "rgba(41, 221, 244, 1)"
              };"></div>`,
            });
          }

          this.texts = timeArr;
        });
        this.pointArr = pointArr;
        o1.setCenter(pointArr[0]);
      }, 500);
    },
    edit(data) {
      // /pdaManager/attend/monitorList/{signDate}/{pdaManagerId}
      this.$axios
        .get(
          `/acb/2.0/pdaManager/attend/monitorList/${data.dataDate}/${this.pdaManagerId}`
          // `/acb/2.0/pdaManager/attend/monitorList/2020-05-22/1662232815874371587`
        )
        .then((res) => {
          if (res.state == 0) {
            this.infoList = res.value;
            this.pointArr = res.value;
            this.getTable(data.dataDate, this.pdaManagerId, res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getTable(data, pdaManagerId, arrlist) {
      let url =
        "/acb/2.0/pdaManager/attend/pdaAttend/" + data + "/" + pdaManagerId;
      this.$axios.get(url).then((res) => {
        let o1 = amapManager.getMap();
        // o1.setZoom(8);
        this.center = [116.398004, 39.914218];
        this.polyline = {
          path: [],
          editable: false,
        };
        this.texts = [];
        this.markers = [];
        let arr = JSON.parse(JSON.stringify(arrlist));
        if (res.value) {
          if (res.state == 0) {
            let o1 = amapManager.getMap();
            o1.setCenter([
              res.value.signinLongitude / 1000000,
              res.value.signinLatitude / 1000000,
            ]);
            if (res.value.signinLongitude) {
              arr.unshift({
                longitude: res.value.signinLongitude,
                latitude: res.value.signinLatitude,
              });
            }
            if (res.value.signoutLongitude) {
              arr.push({
                longitude: res.value.signoutLongitude,
                latitude: res.value.signoutLatitude,
              });
            }
            this.handleData(arr, res.value);
            let go = [];
            let to = [];
            if (res.value.signinLongitude && res.value.signinLatitude) {
              go = [
                res.value.signinLongitude / 1000000,
                res.value.signinLatitude / 1000000,
              ];
            } else {
              go = [];
            }
            if (res.value.signoutLongitude && res.value.signoutLatitude) {
              to = [
                res.value.signoutLongitude / 1000000,
                res.value.signoutLatitude / 1000000,
              ];
            } else {
              to = [];
            }
            setTimeout(() => {
              this.setMap(res.value, go, to);
            }, 500);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        } else {
          // Object.assign(this.amapManager, this.$options.data().amapManager);
          // Object.assign(this.polyline, this.$options.data().polyline);
          // Object.assign(this.markers, this.$options.data().markers);
          // Object.assign(this.pointArr, this.$options.data().pointArr);
          // Object.assign(this.windowsData, this.$options.data().windowsData);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.routeData = this.$route.query;
    this.getPdaInfo();
  },
  watch: {
    dynamicRolling(val) {
      if (val) {
        this.positionSwitch = false;
        this.getinitMap(this.infoList, val);
      } else {
        clearInterval(this.timeinfoShow);
        this.infowindow1.close();
      }
    },
    positionSwitch(val) {
      if (val) {
        this.dynamicRolling = false;
        clearInterval(this.timeinfoShow);
        this.infowindow1.close();
      }
    },
    isPlayback(val) {
      if (val) {
        this.handleCloseCreen();
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
// @import url(); 引入公共css类
.userAttendanceDetails {
  .el-row {
    .el-col {
      .table_wrapper {
        ul {
          border-bottom: 1px solid #cccccc;
          border-right: 1px solid #cccccc;

          li {
            border-top: 1px solid #cccccc;

            label, span {
              display: table-cell;
              width: 172px;
              height: 36px;
              line-height: 36px;
              text-align: center;
              border-left: 1px solid #cccccc;
            }

            label {
              background-color: #f2f2f2;
            }
          }
        }
      }

      .map {
        margin-top: 20px;
        height: 600px;
        width: 100%;
        border: 1px solid #cccccc;

        .amap-demo {
          position: relative;
        }

        &.screen-box {
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 999;
          margin-top: 0;

          .amap-demo {
            width: 75%;
            height: 81%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 8px;
            overflow: hidden;
          }
        }
      }
    }

    .el-col-13 {
      padding: 0px 0px 0 20px;
    }
  }

  .map {
    position: relative;

    .operation-box {
      position: absolute;
      right: 16px;
      top: 16px;
      padding: 8px 16px;
      background-color: #fff;
      border-radius: 4px;

      .position-text {
        color: #707070;
        margin-right: 7px;
        vertical-align: middle;
      }

      .playback-span {
        cursor: pointer;

        &.select {
          color: #4991FF;
        }
      }

      .line-box {
        width: 1px;
        height: 16px;
        background: #dfe1e7;
        margin: 0 15px;
        display: inline-block;
        vertical-align: middle;
      }

      .enlarge-img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
}

/deep/ {
  .amap-overlay-text-container {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    border: none;
  }
}
</style>
<style scope>
.up {
  display: inline-block;
  width: 40px;
  height: 60px;
  background: url("../collectorAttence/up.png") no-repeat;
}
.down {
  display: inline-block;
  width: 40px;
  height: 60px;
  background: url("../collectorAttence/down.png") no-repeat;
}
.trajectory-icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url("../../../assets/img/inspector-icon.png") no-repeat;
  background-size: contain;
}
.amap-demo {
  height: 600px;
}
</style>
